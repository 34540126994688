import React from "react";

import { TextField, Button, Typography, FormControl } from '@mui/material';

import AuthService from "../services/auth.service";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function PassResetRequest() {
  const [message, setMessage] = React.useState("");
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    setMessage("");

    AuthService.passwordResetRequest(data.username).then(
      response => {
        setMessage("Please check your email, spam or https://security.microsoft.com/quarantine");
        setSubmitDisabled(true);

      },
      error => {
        const resMessage =
          (error.response.data.errorMessage) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
      }
    );
  };


  return (
    <div>

      <h3>Password change request</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ m: 1, width: 200 }} >
          <TextField
            required
            id="username"
            size="small"
            label="Username"
            variant="outlined"
            name="username"
            {...register('username')}
            error={errors.username ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.username?.message}
          </Typography>
        </FormControl>

        <FormControl sx={{ m: 1, width: 200 }}>
          <Button
            sx={{ m: 0 }}
            variant="outlined"
            disabled={submitDisabled}
            // onClick={handleLogin}
            onClick={handleSubmit(onSubmit)}
          >Change password</Button>

        </FormControl>

        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}

      </form>

    </div>
  );

}

export default PassResetRequest;