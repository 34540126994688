import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import './App.css';

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Home from "./components/home.component";
import Restructuring from "./components/restructuring.component";
import SelCrit from './components/selCrit.compoment';
import Rules from './components/rules.compoment';
import Settings from './components/settings.compoment';
import PassResetRequest from './components/passResetRequest.component';
import PassChange from './components/passChange.component';
import Documentation from './components/documentation.component';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

const theme = createTheme({
  palette: {
    // mode: 'dark',
    // primary: {
    //   // main: '#ff8217', //orange
    // main: '#0e3a5e', //dark blue
    // },
  },
  typography: {
    fontFamily: ['Archivo', 'Neuzeit', '"Open Sans"', 'Arial', 'sans-serif'].join(','),
    // fontSize: 13,
  }
});



class App extends Component {
  state = {
    msalInstance: null,
    azureLoginInfo: null, // State to cache the Azure login info
  };

  componentDidMount() {
    this.initializeMsal();
  }

  async initializeMsal() {
    try {
      // Check if the login info is already cached
      if (this.state.azureLoginInfo) {
        this.setupMsal(this.state.azureLoginInfo);
        return;
      }

      const response = await AuthService.getAzureLoginInfo();
      if (response.data) {
        this.setState({ azureLoginInfo: response.data }, () => {
          this.setupMsal(response.data);
        });
      } else {
        console.warn("Error getting Entra ID info");
      }
    } catch (error) {
      console.error("Error initializing MSAL:", error);
    }
  }

  setupMsal(data) {
    const { client_id: clientId, authority, redirecturi } = data;

    const msalConfig = {
      auth: {
        clientId: clientId,
        authority: authority,
        redirectUri: redirecturi,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
    };

    const pca = new PublicClientApplication(msalConfig);
    this.setState({ msalInstance: pca });
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const { msalInstance } = this.state;

    const renderLayout = () => (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='container'>
          <Sidebar />
          <div className='content'>
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/restructuring" element={<Restructuring />} />
                <Route path="/selCrit" element={<SelCrit />} />
                <Route path="/rules" element={<Rules />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/passResetRequest" element={<PassResetRequest />} />
                <Route path="/passChange/:resetId" element={<PassChange />} />
                <Route path="/documentation" element={<Documentation />} />
              </Routes>
            </main>
          </div>
        </div>
      </ThemeProvider>
    );

    // Exclude MsalProvider if it's not available
    if (!msalInstance) {
      return renderLayout();
    }

    return (
      <MsalProvider instance={msalInstance}>
        {renderLayout()}
      </MsalProvider>
    );
  }
}

export default App;
