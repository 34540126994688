import React from "react";

import { TextField, Button, Typography, FormControl } from '@mui/material';

import AuthService from "../services/auth.service";

import { useNavigate, useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function PassChange() {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  let { resetId } = useParams();

  const validationSchema = Yup.object().shape({
    passwordConf: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    password: Yup.string()
      .required('Password is required')
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,40}$/,
        "Must Contain 8 Characters, uppercase, lowercase and number"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    setMessage("");

    AuthService.passwordUpdate(resetId, data.password).then(
      response => {
        //password updated, let's go home
        navigate('/home');
        
      },
      error => {
        const resMessage =
          (error.response.data.errorMessage) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
      }
    );
  };


  return (
    <div>

      <h3>Password change</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ m: 1, width: 200 }} >
          <TextField
            required
            id="password"
            size="small"
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            autoComplete="password"
            {...register('password')}
            error={errors.password ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.password?.message}
          </Typography>
        </FormControl>

        <FormControl sx={{ m: 1, width: 200 }} >
          <TextField
            required
            id="passwordConf"
            size="small"
            label="Password confirm"
            variant="outlined"
            name="passwordConf"
            type="password"
            {...register('passwordConf')}
            error={errors.passwordConf ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.passwordConf?.message}
          </Typography>
        </FormControl>


        <FormControl sx={{ m: 1, width: 200 }}>
          <Button
            sx={{ m: 0 }}
            variant="outlined"
            onClick={handleSubmit(onSubmit)}
          >Change password</Button>

        </FormControl>

        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}

      </form>

    </div>
  );

}

export default PassChange;