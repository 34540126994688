Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
}
Number.prototype.padLeft = function (base, chr) {
    var len = (String(base || 10).length - String(this).length) + 1;
    return len > 0 ? new Array(len).join(chr || '0') + this : this;
}

export const formatdate = (timestamp) => {
    if (timestamp == null || timestamp == undefined) {
        return "";
    }

    //get timezone offset, convert to hours
    let timezoneOffset = (new Date()).getTimezoneOffset();
    let timezoneOffsetHours = timezoneOffset / 60 * -1;

    //create date and add offset hours
    let timestampStr = String(timestamp);
    let year = timestampStr.substring(0, 4);
    let month = timestampStr.substring(4, 6);
    let day = timestampStr.substring(6, 8);
    let hour = timestampStr.substring(8, 10);
    let minute = timestampStr.substring(10, 12);
    let second = timestampStr.substring(12, 14);
    let date = new Date(year, month - 1, day, hour, minute, second);
    date.addHours(timezoneOffsetHours);

    //format date to YYYY-MM-DD HH:MM:SS
    return date.getFullYear() //year
        + "-" + (date.getMonth() + 1).padLeft() //month - zero based
        + "-" + date.getDate().padLeft() //day
        + " " + date.getHours().padLeft() //hour
        + ":" + date.getMinutes().padLeft() //minute
        + ":" + date.getSeconds().padLeft(); //second
}


export const getCurrentTimestamp = () => {
    const now = new Date();

    const pad = (num) => num.toString().padStart(2, '0');

    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());
    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());

    const timestamp = `${year}${month}${day}_${hours}${minutes}${seconds}`;

    return timestamp;

}

export const addParamToUrl = (key, value) => {
    let url_string = window.location.href;
    let url = new URL(url_string);

    url.searchParams.delete(key);
    url.searchParams.append(key, value);

    url.search = decodeURIComponent(url.search);
    window.history.replaceState(null, null, url);
}
export const removeParamFromUrl = (key) => {
    let url_string = window.location.href;
    let url = new URL(url_string);

    url.searchParams.delete(key);

    url.search = decodeURIComponent(url.search);
    window.history.replaceState(null, null, url);
}