export const STATUSES = [
    
    {
        "id": "D",
        "val": "Not used"
    },
    {
        "id": "T",
        "val": "To be mapped"
    },
    {
        "id": "M",
        "val": "Mapped"
    },
    {
        "id": "I",
        "val": "Implemented"
    },
    {
        "id": "A",
        "val": "Approved"
    }
];

export const MAPPINGS = [
    {
        "id": 0,
        "val": "Constant"
    },
    {
        "id": 1,
        "val": "Field"
    },
    {
        "id": 2,
        "val": "Rule"
    }
];

export const PERMISSION_EDIT = "MappingEditing";
export const PERMISSION_MAINTENANCE = "Maintenance";

export const SETTING_SHOWSCALE = "showScale";
export const SETTING_LOCKDOWN = "lockdown";
export const SETTING_COLWIDTHBLOCK = "ColumnWidthBlock";
export const SETTING_SHOWSUMMARYDETAIL = "showSummaryDetail";

export const USER_TYPE_LOCAL = "local"
export const USER_TYPE_ENTRAID = "entraID"

export const USER_TYPES = [
    {
        "id": USER_TYPE_LOCAL,
        "val": "Local with password"
    },
    {
        "id": USER_TYPE_ENTRAID,
        "val": "Microsoft Entra ID"
    }
]