import React, { useState } from "react";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import * as constants from '../common/constants'
import Chart from 'react-apexcharts'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function getStatusName(statusId) {
    let statusName = statusId;
    constants.STATUSES.forEach((el) => {
        if (el.id == statusId) {
            statusName = el.val;
        }
    });
    return statusName;
}

function getStatusId(statusName) {
    let statusId = statusName;
    constants.STATUSES.forEach((el) => {
        if (el.val == statusName) {
            statusId = el.id;
        }
    });
    return statusId;
}

function getColor(statusId) {
    switch (statusId) {
        case "D": return "#C0C0C0";
        case "T": return "#f1f1ba";
        case "M": return "#FFE5CC";
        case "I": return "#90ee90";
        case "A": return "#42cc42";
        default: return "#C0C0C0";
    }
}

function Home() {
    const [username, setUsername] = useState("");
    const [charts, setCharts] = useState([]);
    const [chartsSelCrit, setChartsSelCrit] = useState([]);
    const [blockSummary, setBlockSummary] = useState([]);

    const commonStyles = {
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)',
        p: 3,
        m: 0.5
    };

    const sectionHeaderStyles = {
        fontSize: '1.2rem',
        fontWeight: 500,
        color: 'text.primary',
        mb: 1,
        pb: 1,
        borderBottom: '1px solid #e0e0e0'
    };

    React.useEffect(() => {
        let user = AuthService.getCurrentUser();
        setUsername(user.username);

        UserService.getRestructuringSummary().then(
            response => {
                let tmpCharts = [];
                if (response && response.data && Object.keys(response.data).length > 0 && Object.keys(response.data.projects).length > 0) {
                    response.data.projects.forEach((el) => {
                        var labels = [];
                        var series = [];
                        var colors = [];

                        el.statuses.forEach((el) => {
                            labels.push(getStatusName(el.statusName));
                            series.push(el.statusValue);
                            colors.push(getColor(el.statusName))
                        });

                        let pieOptions = {
                            chart: {
                                width: 380,
                                type: 'pie',
                                events: {
                                    dataPointSelection: (event, chartContext, config) => {
                                        window.location.href = '/restructuring?table=[]&block=[]&layout=[]&project=["' + el.name + '"]&status=["' + getStatusId(config.w.config.labels[config.dataPointIndex]) + '"]';
                                    }
                                },
                                animations: {
                                    enabled: false,
                                    // enabled: true,
                                    // speed: 800
                                }
                            },
                            plotOptions: {
                                pie: {
                                    expandOnClick: false
                                }
                            },
                            labels: labels,
                            title: {
                                text: el.name,
                                align: 'center'
                            },
                            colors: colors,
                            dataLabels: {
                                style: {
                                    colors: ['#000'],
                                    textOutline: 'none'
                                }
                            },
                            tooltip: {
                                enabled: true,
                                theme: false,
                                style: {
                                    fontSize: '14px',
                                    fontFamily: undefined
                                }
                            },
                            states: {
                                hover: {
                                    filter: {
                                        type: 'darken',
                                        value: 0.80,
                                    }
                                }
                            }
                        }
                        tmpCharts.push(
                            <Box key={el.name} sx={{ ...commonStyles }} >
                                <Grid item xs={4}>
                                    <Chart options={pieOptions} series={series} type="pie" width={350} />
                                </Grid>
                            </Box>
                        )

                    })
                    setCharts(tmpCharts);
                }
            },
            error => {
                setCharts([]);
                // const resMessage =
                //     (error.response.data.errorMessage) ||
                //     error.message ||
                //     error.toString();
                // enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

        UserService.getSelCritSummary().then(
            response => {
                let tmpCharts = [];
                if (response && response.data && Object.keys(response.data).length > 0 && Object.keys(response.data.projects).length > 0) {
                    response.data.projects.forEach((el) => {
                        var labels = [];
                        var series = [];
                        var colors = [];

                        el.statuses.forEach((el) => {
                            labels.push(getStatusName(el.statusName));
                            series.push(el.statusValue);
                            colors.push(getColor(el.statusName))
                        });

                        let pieOptions = {
                            chart: {
                                width: 350,
                                type: 'pie',
                                events: {
                                    dataPointSelection: (event, chartContext, config) => {
                                        window.location.href = '/selCrit?table=[]&block=[]&layout=[]&project=["' + el.name + '"]&status=["' + getStatusId(config.w.config.labels[config.dataPointIndex]) + '"]';
                                    }
                                },
                                animations: {
                                    enabled: false,
                                    // enabled: true,
                                    // speed: 800
                                }
                            },
                            plotOptions: {
                                pie: {
                                    expandOnClick: false
                                }
                            },
                            labels: labels,
                            title: {
                                text: el.name,
                                align: 'center'
                            },
                            colors: colors,
                            dataLabels: {
                                style: {
                                    colors: ['#000'],
                                    textOutline: 'none'
                                }
                            },
                            tooltip: {
                                enabled: true,
                                theme: false,
                                style: {
                                    fontSize: '14px',
                                    fontFamily: undefined
                                }
                            },
                            states: {
                                hover: {
                                    filter: {
                                        type: 'darken',
                                        value: 0.80,
                                    }
                                }
                            }
                        }
                        tmpCharts.push(
                            <Box key={el.name} sx={{ ...commonStyles }} >
                                <Grid item xs={4}>
                                    <Chart options={pieOptions} series={series} type="pie" width={350} />
                                </Grid>
                            </Box>
                        )

                    })
                    setChartsSelCrit(tmpCharts);
                }
            },
            error => {
                setChartsSelCrit([]);
                // const resMessage =
                //     (error.response.data.errorMessage) ||
                //     error.message ||
                //     error.toString();
                // enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

        UserService.getBlockSummary().then(
            response => {
                if (response && response.data && response.data.projects) {
                    setBlockSummary(response.data.projects);
                }
            },
            error => {
                setBlockSummary([]);
            }
        );
    }, []);

    const renderBlockSummaryTable = () => {
        return (
            <>
                {blockSummary.map((project) => (
                    <Box key={project.name} sx={{ mb: 2 }}>
                        <Typography variant="h7" sx={{ mb: 0 }}>
                            {project.name}
                        </Typography>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow sx={{ 
                                        backgroundColor: '#f5f5f5',
                                        '& th': { 
                                            fontWeight: 'bold',
                                            color: 'text.primary'
                                        }
                                    }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Block</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Mapped</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Implemented</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Not Used</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>To Be Mapped</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Total</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>%</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {project.blocks.map((block) => (
                                        <TableRow 
                                            key={`${project.name}-${block.blockName}`}
                                            sx={{ 
                                                '&:nth-of-type(odd)': {
                                                    backgroundColor: '#fafafa',
                                                },
                                                '&:hover': {
                                                    backgroundColor: '#f5f5f5',
                                                }
                                            }}
                                        >
                                            <TableCell>{block.blockName}</TableCell>
                                            <TableCell align="right">{block.mapped}</TableCell>
                                            <TableCell align="right">{block.implemented}</TableCell>
                                            <TableCell align="right">{block.notUsed}</TableCell>
                                            <TableCell align="right">{block.toBeMapped}</TableCell>
                                            <TableCell align="right">{block.total}</TableCell>
                                            <TableCell align="right">{block.percentage.toFixed(2)}%</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                ))}
            </>
        );
    };

    return (
        <Container maxWidth={false} sx={{ px: 4 }}>
            <Box sx={{ width: '100%', py: 3 }}>
               
                    <h3>Logged in user: {username}</h3>
               

                <Grid container spacing={3}>
                    <Grid item xs={AuthService.getAppSetting(constants.SETTING_SHOWSUMMARYDETAIL)?.value === "true" ? 6 : 12}>
                        <Paper sx={{ ...commonStyles, mb: 1 }}>
                            <Typography sx={sectionHeaderStyles}>
                                Restructuring status for each project
                            </Typography>
                            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                                {charts}
                            </Grid>
                        </Paper>

                        <Paper sx={commonStyles}>
                            <Typography sx={sectionHeaderStyles}>
                                Selection criteria status for each project
                            </Typography>
                            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                                {chartsSelCrit}
                            </Grid>
                        </Paper>
                    </Grid>

                    {AuthService.getAppSetting(constants.SETTING_SHOWSUMMARYDETAIL)?.value === "true" && (
                        <Grid item xs={6}>
                            <Paper sx={commonStyles}>
                                <Typography sx={sectionHeaderStyles}>
                                    Block summary by project
                                </Typography>
                                {renderBlockSummaryTable()}
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Container>
    );
}


export default Home;