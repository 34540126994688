import UserService from "../services/user.service";

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';

import * as constants from '../common/constants';
import { removeParamFromUrl } from "../common/functions";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { useSnackbar } from 'notistack';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



function SelFilter(props) {
    const { enqueueSnackbar } = useSnackbar();

    // available options for filters
    const [filterProjects, setFilterProjects] = React.useState([]);
    const [filterBlocks, setFilterBlocks] = React.useState([]);
    const [filterTables, setFilterTables] = React.useState([]);
    const [filterLayouts, setFilterLayouts] = React.useState([]);

    // selected filter options
    const [selProjects, setSelProjects] = React.useState([]);
    const [selBlocks, setSelBlocks] = React.useState([]);
    const [selTables, setSelTables] = React.useState([]);
    const [selLayouts, setSelLayouts] = React.useState([]);
    const [selStatuses, setSelStatuses] = React.useState([]);

    const [showIdRuleMessage, setShowIdRuleMessage] = React.useState(false);

    const filterStatuses = constants.STATUSES;

    React.useEffect(() => {

        // get params from URL and update the ones to be pre-selected
        let url_string = window.location.href;
        let url = new URL(url_string);
        let project = url.searchParams.get("project");
        if (project) {
            localStorage.setItem("filterProjects", project);
        }
        let block = url.searchParams.get("block");
        if (block) {
            localStorage.setItem("filterBlocks", block);
        }
        let table = url.searchParams.get("table");
        if (table) {
            localStorage.setItem("filterTables", table);
        }
        let layout = url.searchParams.get("layout");
        if (layout) {
            localStorage.setItem("filterLayouts", layout);
        }
        let status = url.searchParams.get("status");
        if (status) {
            localStorage.setItem("filterStatuses", status);
        }

        let tmp;
        tmp = JSON.parse(localStorage.getItem('filterProjects'));
        setSelProjects(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterBlocks'));
        setSelBlocks(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterTables'));
        setSelTables(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterLayouts'));
        setSelLayouts(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterStatuses'));
        setSelStatuses(tmp == null ? [] : tmp);


        UserService.getFilterOptions({}).then(
            response => {
                setFilterProjects(response.data.projects.sort());
                setFilterBlocks(response.data.blocks.sort());
                setFilterTables(response.data.tables.sort());
                setFilterLayouts(response.data.layouts.sort());
                //run update filter to make sure only relevant tables & layouts are available
                updateFilterOptions();
            },
            error => {

            }
        );
        updateUrlBasedOnFilter();
        let filterOptions = buildFilter(false, false, false, true);
        //send the filter to parent
        if (filterOptions) {
            props.handleFilter(filterOptions);
        }
    }, []);


    const theme = useTheme();

    const updateFilterOptions = () => {
        let filterOptions = buildFilter(true, true, true);
        UserService.getFilterOptions(filterOptions).then(
            response => {
                //clear selection for table/layout
                localStorage.removeItem('filterTables');
                localStorage.removeItem('filterLayouts');
                setSelTables([]);
                setSelLayouts([]);

                setFilterTables(response.data.tables.sort());
                setFilterLayouts(response.data.layouts.sort());

            },
            error => {

            }
        );
    };

    const buildFilter = (excludeStatus = false, excludeLayout = false, excludeTable = false, hideWarning = false) => {
        let filterOptions = {}

        let andArray = [];
        let tmp = {};

        // if we get idRule in URL, run filter only on that field
        let url = new URL(window.location.href);
        let idRule = url.searchParams.get("idRule");
        if (idRule) {
            setShowIdRuleMessage(true);
            filterOptions = {
                "or": [
                    {
                        "and": [
                            {
                                "field": "idRule",
                                "expressionType": "=",
                                "value": idRule
                            }
                        ]
                    }
                ]
            }
            return filterOptions;
        }

        let selProjects = JSON.parse(localStorage.getItem('filterProjects'));
        if (selProjects !== undefined && selProjects !== null && selProjects.length > 0) {
            tmp = {
                "field": "project",
                "expressionType": "in",
                "values": selProjects
            }
            andArray.push(tmp);
        } else {
            if (hideWarning !== true) {
                enqueueSnackbar("Project is mandatory!", { variant: 'warning' });
            }
            return;
        }

        if (props.useBlock !== false) {

            let selBlocks = JSON.parse(localStorage.getItem('filterBlocks'));
            if (selBlocks !== undefined && selBlocks !== null && selBlocks.length > 0) {
                tmp = {
                    "field": "block",
                    "expressionType": "in",
                    "values": selBlocks
                }
                andArray.push(tmp);
            }
        }

        if (props.useTable !== false && !excludeTable) {
            let selTables = JSON.parse(localStorage.getItem('filterTables'));
            if (selTables !== undefined && selTables !== null && selTables.length > 0) {
                tmp = {
                    "field": "tableName",
                    "expressionType": "in",
                    "values": selTables
                }
                andArray.push(tmp);
            }
        }


        if (props.useLayout !== false && !excludeLayout) {
            let selLayouts = JSON.parse(localStorage.getItem('filterLayouts'));
            if (selLayouts !== undefined && selLayouts !== null && selLayouts.length > 0) {
                tmp = {
                    "field": "layout",
                    "expressionType": "in",
                    "values": selLayouts
                }
                andArray.push(tmp);
            }
        }


        if (!excludeStatus) {
            let selStatuses = JSON.parse(localStorage.getItem('filterStatuses'));
            if (selStatuses !== undefined && selStatuses !== null && selStatuses.length > 0) {
                tmp = {
                    "field": "status",
                    "expressionType": "in",
                    "values": selStatuses
                }
                andArray.push(tmp);
            }
        }

        if (andArray.length > 0) {
            filterOptions = {
                "or": [
                    {
                        "and": andArray
                    }
                ]
            }
        }
        return filterOptions;
    }

    const handleChangeProject = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterProjects', JSON.stringify(value));
        setSelProjects(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        updateFilterOptions();
    };

    const handleChangeBlock = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterBlocks', JSON.stringify(value));
        setSelBlocks(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        updateFilterOptions();
    };

    const handleChangeTable = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterTables', JSON.stringify(value));
        setSelTables(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeLayout = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterLayouts', JSON.stringify(value));
        setSelLayouts(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterStatuses', JSON.stringify(value));
        setSelStatuses(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const clearFilter = (event) => {
        localStorage.removeItem('filterBlocks');
        localStorage.removeItem('filterTables');
        localStorage.removeItem('filterLayouts');
        localStorage.removeItem('filterStatuses');

        setSelBlocks([]);
        setSelTables([]);
        setSelLayouts([]);
        setSelStatuses([]);

    }

    const updateUrlBasedOnFilter = () => {

        let url_string = window.location.href;
        let url = new URL(url_string);

        // remove all params and add only the selected ones
        url.searchParams.delete('project');
        url.searchParams.delete('block');
        url.searchParams.delete('table');
        url.searchParams.delete('layout');
        url.searchParams.delete('status');

        let selProjects = JSON.parse(localStorage.getItem('filterProjects'));
        if (selProjects !== undefined && selProjects !== null && selProjects.length > 0) {
            url.searchParams.append('project', JSON.stringify(selProjects));
        }
        let selBlocks = JSON.parse(localStorage.getItem('filterBlocks'));
        if (selBlocks !== undefined && selBlocks !== null && selBlocks.length > 0) {
            url.searchParams.append('block', JSON.stringify(selBlocks));
        }
        let selTables = JSON.parse(localStorage.getItem('filterTables'));
        if (selTables !== undefined && selTables !== null && selTables.length > 0) {
            url.searchParams.append('table', JSON.stringify(selTables));
        }
        let selLayouts = JSON.parse(localStorage.getItem('filterLayouts'));
        if (selLayouts !== undefined && selLayouts !== null && selLayouts.length > 0) {
            url.searchParams.append('layout', JSON.stringify(selLayouts));
        }
        let selStatuses = JSON.parse(localStorage.getItem('filterStatuses'));
        if (selStatuses !== undefined && selStatuses !== null && selStatuses.length > 0) {
            url.searchParams.append('status', JSON.stringify(selStatuses));
        }

        url.search = decodeURIComponent(url.search);
        window.history.replaceState(null, null, url);
    }

    const filterEvent = (event) => {
        // always remove idRule from url since this is only temporary filter
        removeParamFromUrl('idRule');
        setShowIdRuleMessage(false);
        updateUrlBasedOnFilter();

        let filterOptions = buildFilter();
        //send the filter to parent
        if (filterOptions) {
            props.handleFilter(filterOptions);
        }

    }

    const exportEvent = (event) => {
        
        let filterOptions = buildFilter();
        //send the filter to parent
        if (filterOptions) {
            props.handleExport(filterOptions);
        }

    }

    return (

    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <Box sx={{ display: 'flex', gap: 0, alignItems: 'center', flexWrap: 'wrap' }}>
            {showIdRuleMessage ? <Stack sx={{ width: '100%', pb: 1 }} >
                <Alert variant="outlined" sx={{
                    height: 27, pb: 0, pt: 0,
                    "& .MuiAlert-icon": {
                        fontSize: 20,
                        paddingTop: 0.3,
                        paddingBottom: 0.3
                    },
                    "& .MuiAlert-message": {
                        paddingTop: 0.3,
                        paddingBottom: 0.3
                    }
                }} severity="info">Showing only fields for selected rule. Press "FILTER" to display all.</Alert>
            </Stack>
                : ""}
            <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                <InputLabel id="filterProject-label">Project</InputLabel>
                <Select
                    labelId="filterProject-label"
                    id="filterProject"
                    multiple
                    value={selProjects}
                    onChange={handleChangeProject}
                    input={<OutlinedInput id="select-multiple-chip" label="Project" />}
                    MenuProps={MenuProps}
                >
                    {filterProjects.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, selProjects, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {props.useBlock !== false ?
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterBlock-label">Block</InputLabel>
                    <Select
                        labelId="filterBlock-label"
                        id="filterBlock"
                        multiple
                        value={selBlocks}
                        onChange={handleChangeBlock}
                        input={<OutlinedInput id="select-multiple-chip" label="Block" />}
                        MenuProps={MenuProps}
                    >
                        {filterBlocks.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, selBlocks, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                : ""}
            {props.useTable !== false ?
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterBlock-label">Table</InputLabel>
                    <Select
                        labelId="filterBlock-label"
                        id="filterBlock"
                        multiple
                        autoWidth
                        value={selTables}
                        onChange={handleChangeTable}
                        input={<OutlinedInput id="select-multiple-chip" label="Table" />}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
                                },
                            },
                        }}
                    >
                        {filterTables.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, selTables, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                : ""}
            {props.useTable !== false ?
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterLayout-label">Layout</InputLabel>
                    <Select
                        labelId="filterLayout-label"
                        id="filterLayout"
                        multiple
                        value={selLayouts}
                        onChange={handleChangeLayout}
                        input={<OutlinedInput id="select-multiple-chip" label="Layout" />}
                        MenuProps={MenuProps}
                    >
                        {filterLayouts.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, selLayouts, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                : ""}
            <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                <InputLabel id="filterStatus-label">Status</InputLabel>
                <Select
                    labelId="filterStatus-label"
                    id="filterStatus"
                    multiple
                    value={selStatuses}
                    onChange={handleChangeStatus}
                    input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                    MenuProps={MenuProps}
                >
                    {filterStatuses.map(option => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                {option.val}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <Button
                sx={{ margin: 0.2 }}
                variant="outlined"
                onClick={clearFilter}
            >Clear</Button>

            <Button
                sx={{ margin: 0.2 }}
                variant="outlined"
                onClick={filterEvent}
            >Filter</Button>
             </Box>
             <Box sx={{ 
                marginTop: '0.1em'
            }}>
        {props.showExport === true ?
                <Button
                sx={{ margin: 0.2 }}
                variant="outlined"
                onClick={exportEvent}
            >Export</Button>
                : ""}
        </Box>
        </Box>
    )
}

export default SelFilter;