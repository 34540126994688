import React from "react";
import { marked } from "marked";
import docs from '../common/documentation.md'


function Documentation() {

  const [docsText, setDocsText] = React.useState('');

  const getMarkdownText = () => {
    let rawMarkup = '';
    fetch(docs)
      .then(r => r.text())
      .then(text => {
        setDocsText(marked.parse(text));
      });
    return { __html: docsText };
  }
  return (
    <div>

      <h3>Documentation</h3>
      <div dangerouslySetInnerHTML={getMarkdownText()} />
    </div>
  );

}

export default Documentation;