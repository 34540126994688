import React from "react";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import * as constants from '../common/constants'

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { useSnackbar } from 'notistack';

import { randomId } from '@mui/x-data-grid-generator';

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    useGridApiContext,
} from '@mui/x-data-grid';

import { triggerBase64Download } from "../common/base64downloader";

function RuleEditDialog(props) {

    const { enqueueSnackbar } = useSnackbar();
    const [statusEdit, setStatusEdit] = React.useState("");
    const [isMN, setIsMN] = React.useState(false);
    const [definitionEdit, setDefinitionEdit] = React.useState("");
    const [commentEdit, setCommentEdit] = React.useState("");
    const [nameEdit, setNameEdit] = React.useState("");
    const [projectEdit, setProjectEdit] = React.useState("");
    const [tableMeta, setTableMeta] = React.useState("{}");
    const [ruleId, setRuleId] = React.useState(0);
    const [mnStructureChanged, setMnStructureChanged] = React.useState(false);

    const [openDialogEditStructure, setOpenDialogEditStructure] = React.useState(false);

    const [rowsStructure, setRowsStructure] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const [allProjects, setAllProjects] = React.useState([]);

    const [manChecked, setManChecked] = React.useState(false);
    const [dupChecked, setDupChecked] = React.useState(false);

    React.useEffect(() => {
        if (Object.keys(props.rowToEdit).length > 0) {
            clearDialog();
            setDefinitionEdit(props.rowToEdit.definition);
            setStatusEdit(props.rowToEdit.status);
            setIsMN(props.rowToEdit.manyToMany === 1 ? true : false);
            setCommentEdit(props.rowToEdit.comment);
            setNameEdit(props.rowToEdit.name);
            setProjectEdit(props.rowToEdit.project);
            setTableMeta(props.rowToEdit.tableMeta);
            setRuleId(props.rowToEdit.id);
            setMnStructureChanged(false);
        } else {
            //new rule being created
            setRuleId(0);
            setTableMeta("{}");
            refreshProjects();
            //set a default project
            setProjectEdit(allProjects[0]?.name);
        }


    }, [props.rowToEdit]);

    const clearDialog = () => {
        setStatusEdit("");
        setIsMN(false);
        setDefinitionEdit("");
        setCommentEdit("");
        setNameEdit("");
        setProjectEdit("");
        setRowsStructure([]);
        setMnStructureChanged(false);
    }

    const handleCloseEditStructure = (reload) => {
        setRowsStructure([]);
        setOpenDialogEditStructure(false);

    };

    const refreshProjects = () => {
        UserService.getProjectsAndBlocks().then(
            response => {
                setAllProjects(response.data.projects.map(x => { return { 'id': x, 'name': x } }));
            },
            error => {
                setAllProjects([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    const getTableMeta = () => {
        // remove id and isNew
        let tmpRows = rowsStructure.map((({ id, isNew, ...row }) => row));
        let tableMetaObj = {
            "columns": tmpRows,
            "hasDupColumn": dupChecked, //TODO make these editable - not needed for now
            "hasManColumn": manChecked //TODO make these editable - not needed for now
        };
        return JSON.stringify(tableMetaObj);

    }

    const handleCloseSaveStructure = (reload) => {
        setTableMeta(getTableMeta());
        setRowsStructure([]);
        setOpenDialogEditStructure(false);
        setMnStructureChanged(true);
    };

    const showEditStructureDialog = () => {
        let tableMetaTmp = JSON.parse(tableMeta !== undefined ? tableMeta : "{}");
        setManChecked(tableMetaTmp.hasManColumn);
        setDupChecked(tableMetaTmp.hasDupColumn);
        let mnColumns = [];
        let tmpCols = tableMetaTmp ? tableMetaTmp.columns : undefined;
        if (rowsStructure.length === 0 && tmpCols !== undefined) {
            mnColumns = tmpCols.map(obj => ({ ...obj, id: randomId() }));
            setRowsStructure(mnColumns);
        }

        setOpenDialogEditStructure(true);

    }


    const handleCloseEdit = () => {
        clearDialog();
        props.onClose(true, 0);
    };

    const handleSaveEdit = () => {
        updateRule(false);
    };

    const updateRule = (closeEditStructure) => {
        let row = {
            ...props.rowToEdit
        };
        let isNew = false;
        if (ruleId === 0) {
            isNew = true;
        }
        const id = ruleId;

        row.id = null;

        row.comment = commentEdit;
        row.status = statusEdit;
        row.definition = definitionEdit;
        row.manyToMany = isMN ? "1" : "0";
        row.project = projectEdit;
        row.name = nameEdit;
        row.lock = 0;
        row.tableMeta = tableMeta;

        if (isNew) {
            UserService.createRule(row).then(res => {
                setRuleId(res.data.id)
                if (isMN) {
                    UserService.createRuleTable(res.data.id, {}).then(resCreate => {
                        enqueueSnackbar('Successfully created rule ' + nameEdit, { variant: 'success' });
                        clearDialog();
                        props.onClose(true, res.data.id);
                    },
                        error => {
                            const resMessage =
                                (error.response.data.errorMessage) ||
                                error.message ||
                                error.toString();
                            enqueueSnackbar(resMessage, { variant: 'error' });
                        });
                } else {
                    enqueueSnackbar('Successfully created rule ' + nameEdit, { variant: 'success' });
                    clearDialog();
                    props.onClose(true, res.data.id);
                }
            },
                error => {
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                });

        } else {

            UserService.updateRule(id, row).then(res => {
                if (isMN && mnStructureChanged) {
                    UserService.createRuleTable(id, {}).then(resCreate => {
                        enqueueSnackbar('Successfully updated rule ' + nameEdit, { variant: 'success' });

                        clearDialog();
                        props.onClose(true, ruleId);
                        if (resCreate.data.originalData) {
                            triggerBase64Download('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
                                + resCreate.data.originalData, 'rule_' + props.rowToEdit.name);
                        }
                    },
                        error => {
                            const resMessage =
                                (error.response.data.errorMessage) ||
                                error.message ||
                                error.toString();
                            enqueueSnackbar(resMessage, { variant: 'error' });
                        });
                } else {
                    enqueueSnackbar('Successfully updated rule ' + nameEdit, { variant: 'success' });
                    clearDialog();
                    props.onClose(true, ruleId);
                }
            },
                error => {
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                });
        }
    }

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setRowsStructure(rowsStructure.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rowsStructure.find((row) => row.id === id);
        if (editedRow != null && editedRow.isNew) {
            setRowsStructure(rowsStructure.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRowsStructure(rowsStructure.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const SelectEditInputCell = (props) => {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{ height: 1 }}
                native
            // autoFocus
            >
                <option>NVARCHAR</option>
                <option>DECIMAL</option>
            </Select>
        );
    }

    const renderSelectEditInputCell = (params) => {
        return <SelectEditInputCell {...params} />;
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Column name',
            width: 150,
            editable: true
        },
        {
            field: 'dataType',
            headerName: 'Data type',
            width: 140,
            editable: true,
            renderEditCell: renderSelectEditInputCell
        },
        {
            field: 'length',
            headerName: 'Length',
            type: 'number',
            width: 80,
            editable: true,
        },
        {
            field: 'scale',
            headerName: 'Scale',
            type: 'number',
            width: 80,
            editable: true,
        },
        {
            field: 'sourceColumn',
            headerName: 'Is source',
            type: 'boolean',
            width: 80,
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const EditToolbar = (propsEdit) => {
        const handleClick = () => {

            const id = randomId();
            let tmpRows = [];

            tmpRows = [
                ...rowsStructure
            ];

            tmpRows.push({ id: id, "dataType": 'NVARCHAR', "length": 0, "name": '', "scale": 0, sourceColumn: false });
            setRowsStructure(tmpRows);

        };

        return (
            <GridToolbarContainer>
                <Button
                    sx={{ mr: 5 }}
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleClick}>
                    Add new column
                </Button>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={manChecked}
                            onChange={(event) => {
                                setManChecked(event.target.checked);
                            }}
                        />
                    }
                    label="Use MAN"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dupChecked}
                            onChange={(event) => {
                                setDupChecked(event.target.checked);
                            }}
                        />
                    }
                    label="Use DUP"
                />
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            <Dialog
                open={openDialogEditStructure}
                onClose={handleCloseEditStructure}
                fullWidth={true}
                maxWidth="md"
                disableEscapeKeyDown
            >
                <DialogTitle>Edit M:N struture</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <Box
                        sx={{
                            height: 500,
                            width: '100%',
                            '& .actions': {
                                color: 'text.secondary',
                            },
                            '& .textPrimary': {
                                color: 'text.primary',
                            },
                        }}
                    >
                        <DataGrid
                            rows={rowsStructure}
                            columns={columns}
                            editMode="row"
                            rowHeight={32}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                            processRowUpdate={processRowUpdate}
                            disableRowSelectionOnClick
                            slots={{
                                toolbar: EditToolbar,
                            }}
                            slotProps={{
                                toolbar: { setRows: setRowsStructure, setRowModesModel },
                            }}
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditStructure}>Cancel</Button>
                    <Button onClick={handleCloseSaveStructure}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={props.openDialogEdit}
                onClose={handleCloseEdit}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>{ruleId === 0 ? "New rule" : "Edit rule " + nameEdit + " (" + ruleId + ")"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    {ruleId === 0 ?
                        <div>
                            <FormControl sx={{ m: 1, width: 200 }} >
                                <TextField id="nameEdit-basic"
                                    label="Name"
                                    variant="outlined"
                                    onChange={event => setNameEdit(event.target.value)}
                                    inputProps={{ maxLength: 20 }} />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 200 }} >
                                <InputLabel id="projectEdit-label">Project</InputLabel>
                                <Select
                                    labelId="projectEdit-label"
                                    id="projectEdit"
                                    value={projectEdit}
                                    onChange={event => setProjectEdit(event.target.value)}
                                    input={<OutlinedInput id="select-multiple-chip" label="Project" />}
                                // MenuProps={MenuProps}
                                >
                                    {allProjects.map(option => {
                                        return (
                                            <MenuItem key={option.name} value={option.name}>
                                                {option.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        : ""}

                    <FormControl sx={{ m: 1, width: 200 }} >
                        <InputLabel id="statusEdit-label">Status</InputLabel>
                        <Select
                            labelId="statusEdit-label"
                            id="statudEdit"
                            value={statusEdit === "" ? setStatusEdit("M") : statusEdit}
                            onChange={event => setStatusEdit(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        // MenuProps={MenuProps}
                        >
                            {constants.STATUSES.map(option => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <br />
                    <FormControlLabel
                        sx={{ m: 1, width: 100 }}
                        control={<Checkbox
                            onChange={(event) => {
                                setIsMN(event.target.checked);
                            }}
                            checked={isMN}
                        />}
                        label="M:N" />
                    {isMN && (isMN !== undefined) ?
                        <Button onClick={showEditStructureDialog}>Edit structure</Button>
                        : ""}
                    <br />
                    <FormControl sx={{ m: 1, width: 800 }}>
                        <TextField id="outlined-basic"
                            label="Definition"
                            variant="outlined"
                            multiline
                            maxRows={16}
                            value={definitionEdit}
                            onChange={event => setDefinitionEdit(event.target.value)} />
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: 800 }}>
                        <TextField id="outlined-basic"
                            label="Comment"
                            variant="outlined"
                            multiline
                            maxRows={4}
                            value={commentEdit}
                            onChange={event => setCommentEdit(event.target.value)} />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button onClick={handleSaveEdit}>Save</Button>
                        : ""}
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default RuleEditDialog;