import React from "react";
import AuthService from "../services/auth.service";

import * as constants from '../common/constants'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';


function UpdateStatusMultiple(props) {
    
    const [statusEdit, setStatusEdit] = React.useState("");

    const handleCloseEdit = () => {
        props.onClose(true, 0);
    };

    const handleSaveEdit = () => {
        props.handleUpdateStatusMultiple(statusEdit);
    };

    return (
        <div>
            <Dialog
                open={props.openDialogEditMultiple}
                onClose={handleCloseEdit}
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle>Update status for selected</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    
                    <FormControl sx={{ m: 1, width: 200 }} >
                        <InputLabel id="statusEdit-label">Status</InputLabel>
                        <Select
                            labelId="statusEdit-label"
                            id="statudEdit"
                            value={statusEdit === "" ? setStatusEdit("M") : statusEdit}
                            onChange={event => setStatusEdit(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        >
                            {constants.STATUSES.map(option => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button onClick={handleSaveEdit}>Save</Button>
                        : ""}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UpdateStatusMultiple;