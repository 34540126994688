import React from "react";

import { TextField, Button, Link, Box, Typography, FormControl } from '@mui/material';

import AuthService from "../services/auth.service";

import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus  } from "@azure/msal-browser";

function Login() {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [isEntraId, setIsEntraId] = React.useState(false);

 

  const { instance, inProgress  } = useMsal();
  
  const handleLogin = async () => {
    try {
      let loginResponse;
      if (inProgress === InteractionStatus.None) {
        
        loginResponse = await instance.loginPopup({
          scopes: ["email"], // Replace with your scope
          prompt: "select_account"
        });

        // After successful login, set the active account
        instance.setActiveAccount(loginResponse.account);

        // Now get the token
        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ["email"],
          account: loginResponse.account  // Explicitly provide the account
        });

        const accessToken = tokenResponse.accessToken;

        // console.log("Access Token:", tokenResponse.accessToken);
        
        // // Optional: Log additional information
        // console.log("Account:", loginResponse.account);
        // console.log("ID Token Claims:", loginResponse.idTokenClaims);

        AuthService.validateToken(accessToken).then(
          response => {
            if (response.data.token) {

              //if token is received, then get permissions and save that in localStorage
              AuthService.getPermissionsAndSettingsRequest(response.data.token).then(res => {
                if (res.data) {
                  response.data.permissions = res.data.permissions?? []
                  response.data.settings = res.data.settings?? []
                  // response.data.username = data.username;
                  localStorage.setItem("user", JSON.stringify(response.data));
                  navigate('/home');
                  window.location.reload();

                } else {
                  response = "login error";
                }

              })
            } else {
              response = "error getting token";
            }

          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.errorMessage) ||
              error.message ||
              error.toString();

            setMessage(resMessage);
          }
        );
      }
    } catch (error) {
      if (error.errorCode === "user_cancelled") {
          console.log("User canceled the login flow.");
      } else {
          console.error("An error occurred during login:", error);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
    password: Yup.string()
      .required('Password is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    setMessage("");

    AuthService.login(data.username, data.password).then(
      response => {
        if (response.data.token) {

          //if token is received, then get permissions and save that in localStorage
          AuthService.getPermissionsAndSettingsRequest(response.data.token).then(res => {
            if (res.data) {
              response.data.permissions = res.data.permissions?? []
              response.data.settings = res.data.settings?? []
              response.data.username = data.username;
              localStorage.setItem("user", JSON.stringify(response.data));
              navigate('/home');
              window.location.reload();

            } else {
              response = "login error";
            }

          })
        } else {
          response = "error getting token";
        }

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
      }
    );
  };

  React.useEffect(() => {
    const checkClientId = () => {
      if (!instance?.controller?.config?.auth?.clientId) {
        setIsEntraId(false);
      } else {
        setIsEntraId(true);
      }
    };

    if (inProgress === "none") {
      checkClientId();
    }
  }, [instance, inProgress]);


  return (
    <div>

      <h3>Login</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ m: 1, width: 200 }} >
          <TextField
            required
            id="username"
            size="small"
            label="Username"
            variant="outlined"
            name="username"
            autoComplete="password"
            {...register('username')}
            error={errors.username ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.username?.message}
          </Typography>
        </FormControl>

        <FormControl sx={{ m: 1, width: 200 }} >
          <TextField
            required
            id="password"
            size="small"
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            autoComplete="password"
            {...register('password')}
            error={errors.password ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.password?.message}
          </Typography>
        </FormControl>


        <FormControl sx={{ m: 1, width: 200 }}>
          <Button
            sx={{ m: 0 }}
            variant="outlined"
            type="submit"
            // onClick={handleLogin}
            onClick={handleSubmit(onSubmit)}
          >Login</Button>

        </FormControl>
        <Box>
          <Link href="/passResetRequest" underline="hover">
            {'Having issues? Reset your password'}
          </Link>
        </Box>

        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}

      </form>
      {isEntraId ? 
        <div>
          <Button
              sx={{ m: 2 }}
              size="large"
              variant="outlined"
              type="submit"
              // onClick={handleLogin}
              onClick={handleLogin}
              style={{textTransform: 'none'}}
            >
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><title>MS-SymbolLockup</title><rect x="1" y="1" width="9" height="9" fill="#f25022"/><rect x="1" y="11" width="9" height="9" fill="#00a4ef"/><rect x="11" y="1" width="9" height="9" fill="#7fba00"/><rect x="11" y="11" width="9" height="9" fill="#ffb900"/></svg>
          &nbsp;Sign in with Microsoft</Button>
        </div>
      : "" }
    </div>
  );

}

export default Login;